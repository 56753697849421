$font-regular: 'Barlow-Regular';
$font-medium: 'Barlow-Medium';
$font-bold: 'Barlow-Bold';
$font-semi: 'Barlow-SemiBold';
$font-text: 'Roboto-Regular';
$font-roboto-black: 'Roboto-Black';
$font-roboto-bold: 'Roboto-Bold';

$color-red: #E55C5C;
$color-1: #F4F4F4;
$color-2: #889299;
$color-3: #475763;
$color-input: #CCCCCC;
$color-white: #FFFFFF;
$color-white-25: rgba(255, 255, 255, 0.25);
$color-menu-bkg: #1C2E3E;
$color-menu-bkg-light: rgba(28, 46, 62, 0.9);
$color-menu-bkg-light-75: rgba(28, 46, 62, 0.75);
$color-menu-bkg-light-2: rgba(28, 46, 62, 0.25);
$color-menu-txt: #66A6C4;
$color-black-90: rgba(0, 0, 0, 0.9);
$color-black-65: rgba(0, 0, 0, 0.65);
$color-lightbox-bkg: rgba(48, 64, 78, 0.93);
$green: rgba(112, 112, 112, 0.2);
$color-dark-bkg: #0D1B27;

$mobile-width: 90%;
$container: 1030px;
$submenu-background: #f6f9fc;

@mixin radius($int) {
  -webkit-border-radius: $int;
  -moz-border-radius: $int;
  border-radius: $int;
}

@mixin radius_top_left($int) {
  -webkit-border-top-left-radius: $int;
  -moz-border-top-left-radius: $int;
  border-top-left-radius: $int;
}
@mixin radius_top_right($int) {
  -webkit-border-top-right-radius: $int;
  -moz-border-top-right-radius: $int;
  border-top-right-radius: $int;
}

@mixin radius_bottom_left($int) {
  -webkit-border-bottom-left-radius: $int;
  -moz-border-bottom-left-radius: $int;
  border-top-bottom-radius: $int;
}
@mixin radius_bottom_right($int) {
  -webkit-border-bottom-right-radius: $int;
  -moz-border-bottom-right-radius: $int;
  border-top-bottom-radius: $int;
}

@mixin transition($q, $time, $speed){
  -webkit-transition: $q $time $speed;
  -moz-transition: $q $time $speed;
  -o-transition: $q $time $speed;
  transition: $q $time $speed;
}

@mixin bkg_cover(){
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  background-repeat-x: no-repeat;
  background-repeat-y: no-repeat;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@mixin sticky(){
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
}

@mixin rotate($deg){
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -ms-transform: rotate($deg);
  -o-transform: rotate($deg);
  transform: rotate($deg);
}