@import "variables";

#fp-nav ul li a span,
.fp-slidesNav ul li a span{
  height: 11px;
  width: 11px;
  margin: -6px 0 0 -6px;
  background-color: $color-white;
}

#fp-nav ul li a.active span, #fp-nav ul li:hover a.active span, .fp-slidesNav ul li a.active span, .fp-slidesNav ul li:hover a.active span{
  height: 11px;
  width: 11px;
  background-color: $color-menu-txt;

  &:after{
    content: none;
  }
}

#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span{
  height: 11px;
  width: 11px;
  margin: -6px 0 0 -6px;
  background-color: $color-menu-txt;

  &:after{
    content: none;
  }
}

#fp-nav ul li a span{
  &:after{
    content: '';
    width: 7px;
    height: 7px;
    background-color: #000;
    position: absolute;
    border-radius: 20px;
    top: 2px;
    left: 2px;
  }
}

#fp-nav ul li, .fp-slidesNav ul li{
  &:last-child{
    display: none;
  }
}

.fp-section.fp-table, .fp-slide.fp-table{
  overflow: hidden;
  position: relative;
}

@media (max-width: 425px) {
  #fp-nav ul li, .fp-slidesNav ul li {
    width: 9px;
    height: 9px;
  }
  #fp-nav ul li a span, .fp-slidesNav ul li a span{
    height: 8px;
    width: 8px;
  }
  #fp-nav ul li a span:after{
    width: 6px;
    height: 6px;
    top: 1px;
    left: 1px;
  }
  #fp-nav ul li a.active span, #fp-nav ul li:hover a.active span, .fp-slidesNav ul li a.active span, .fp-slidesNav ul li:hover a.active span{
    height: 9px;
    width: 9px;
  }

}